#login, #adminLogin{
    margin-top: 12%;
    width:40%;
    margin-left: 30%;
    border : 1px solid #de6c5a;
    border-radius: 10px;
    padding: 3% 2% 1% 2%;
    box-shadow: 0px 0px 8px 0px  #de6c5a;
}
#signup{
    margin-top: 8%;
    width:60%;
    margin-left: 20%;
    border : 1px solid #de6c5a;
    border-radius: 10px;
    padding: 3% 2% 1% 2%;
    box-shadow: 0px 0px 8px 0px  #de6c5a;
}
#login  h3, #signup h3, #adminLogin h3{
    text-transform: uppercase;
    font-weight: bold;
    color : #de6c5a;
    font-size: 20px;
}
.loginForm input[type="text"], .loginForm input[type="password"]{
    width:100%;
    padding: 2%;
    margin-top: 5%;
    border: 1px solid #8db9bd;
    border-radius: 10px;
    font-size: 12px;
}
.loginForm button[type="submit"], .RegForm button[type="submit"]{
    width:20%;
    padding: 2%;
    margin-top: 2%;
    margin-left: 40%;
    border: 1px solid #8db9bd;
    border-radius: 10px;
    margin-top: 5%;
    text-transform: uppercase;
    background-color: #8db9bd;
    font-size: 12px;
    color: white;
}
.RegForm button[type="submit"]{
    width:20%;
    padding: 1%;
}
.RegForm{
    margin-top: 15px;
}
.RegForm input[type="text"],.RegForm input[type="tel"],.RegForm input[type="date"],
.RegForm input[type="number"],.RegForm input[type="email"],.RegForm input[type="password"],.Select-std{
    padding: 7px;
    margin-top: 0%;
    border: 1px solid #8db9bd;
    border-radius: 10px;
    font-size: 12px;
    width: 100%;
    color:grey;
    font-family:'Courier New', Courier, monospace;
}
.RegForm input[type="text"]{
    text-transform: capitalize;
}
.RegForm input[type="email"]{
    text-transform: lowercase;
}

.lebel{
    margin-top: 3%;
    color: grey;
    text-transform: uppercase;
    font-size: 12px;
    font-family:'Courier New', Courier, monospace
}

@media screen and (max-width: 600px) {
    #login, #adminLogin{
        width:90%;
        margin-top: 30%;
        margin-left: 5%;
        padding: 7% 2% 7% 2%;
    }
    #signup{
        width:90%;
        margin-top: 30%;
        margin-left: 5%;
        padding: 7% 2% 7% 2%;
    }
    .RegForm button[type="submit"]{
        width:40%;
        padding: 2%;
        margin-left: 30%;
    }

}