#contact  p{
    font-size: 16px;
    text-decoration: none;
    font-family:'Times New Roman', Times, serif;
    text-align: justify;
  }

#contact  h1{
    font-family:'Times New Roman', Times, serif;
    text-align: justify;
    text-transform: uppercase;
  }

  .fa-whatsapp{
    color: green;
  }