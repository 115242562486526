* {
    font-family: "Times New Roman", Georgia, Serif;
}
h1, h2, h3, h4, h5, h6 {
    font-family: 'Times New Roman', Times, serif;
    letter-spacing: 3px;
  }
  a:hover{
    cursor: pointer;
  }

  .p-5{
    padding: 10%;
  }

.myNav,
.myNav-mob {
    background-color: white;
    padding: 10px 40px 10px 20px;
    box-shadow: 0px 1px 10px 0px;
    font-size: 14px;
    position: fixed;
    width: 100%;
    top:0px;
    z-index: 10;
}

.myNav a,
.myNav-mob a {
    text-decoration: none;
    color: black;
    margin-left: 10px;
}
.myNav a{
    padding: 5px;
}

.navItmes a:hover {
    box-shadow: 0px 0px 10px #de6c5a;
    border-radius: 5px;
    color : #8db9bd;
}
#loggedInUser, #loggedInUser_mob {
    box-shadow: 0px 0px 10px #de6c5a;
    border-radius: 5px;
    color : #8db9bd;
}

.schoolName {
    font-size: 25px;
    line-height: 1;
    text-transform: uppercase;
    color: #de6c5a;
    font-weight: bolder;
    letter-spacing: 1px;
    text-align: center;
    margin-left: 2%;
    position: relative;
}

.publicSchool {
    font-size: 12px;
    text-transform: uppercase;
    line-height: 0;
    color: #8db9bd;
    letter-spacing: 10px;
    font-weight: bolder;
    position : absolute;
    top:120%;
    left: 10%;
}
.navItmes{
    float: right;
    padding-top: 22px;
}
#toggle {
    background-color: #f2f2f2;
    z-index: 10;
    position: fixed;
    width: 100%;
    top:8%;
}

#toggle ul {
    list-style-type: none;
    margin: 0px;
    padding: 15px;
    font-size: 14px;
}

#toggle li {
    margin-top: 8px;
}

#toggle li a {
    text-decoration: none;
    color: black;
}

.myNav-mob {
    display: none;
}
#about{
    margin-top: 8%;
}
#menu{
    margin-top: 8%;
}
#contact{
    margin-top: 8%;
}
#menu h4{
    text-transform: uppercase;
    font-size: 18px;
  }
#menu p{
    font-size: 16px;
    color: grey;
    line-height: 4;
  }
  @media screen and (max-width:1150px){     
    .myNav {
        display: none;
    }

    .myNav-mob {
        display: block;
    }
    .schoolName {
        font-size: 25px;
    }
    
    .publicSchool {
        font-size: 14px;
        letter-spacing: 5px;
        top:130%;
        left: 14%;
    }
}
/* #adminNavItmes{
    display: none;
} */
@media screen and (max-width: 600px) {
    .home{
        margin-top: 13%;
    }
    .schoolName {
        font-size: 16px;
        margin-left: 10%;
    }
    
    .publicSchool {
        font-size: 10px;
        letter-spacing: 5px;
        top:130%;
        left: 10%;
    }
    #about{
        margin-top: 0%;
    }
    #menu{
        margin-top: 20%;
    }
    #contact{
        margin-top: 20%;
    }
}
